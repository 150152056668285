import _ from 'lodash'
import ArrayUtils from "@/commons/utils/array.utils";

export default {
	
	/**
	 * Hex 를 Decimal 로 변환
	 *
	 * @param _hex
	 */
	hexToDec: function (_hex) {
		return parseInt(_hex, 16)
	},
	
	/**
	 * Hex 를 Binary 로 변환
	 *
	 * @param _hex
	 */
	hexToBin: function (_hex) {
		return ('00000000' + (parseInt(_hex, 16)).toString(2)).substr(-8)
	},
	
	/**
	 * Hex 를 Ascii 로 변환
	 *
	 * @param _hex
	 */
	hexToAscii: function (_hex) {
		const hex = _hex.toString()
		let str = ''
		for (let i = 0; i < hex.length; i += 2) {
			str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
		}
		return str
	},
	
	/**
	 * ByteArray 를 Hex 로 변환
	 *
	 * @param _byteArr
	 */
	byteArrToHex: function (_byteArr) {
		return Array.from(_byteArr, function (byte) {
			return ('0' + (byte & 0xFF).toString(16)).slice(-2)
		}).join('')
	},
	
	/**
	 * request header 를 설정
	 *
	 * @param xhr
	 * @param _map
	 */
	setAjaxRequestHeaders: function (xhr, _map) {
		_.forEach(_map, (v, k) => {
			xhr.setRequestHeader(k, v)
		})
	},
	
	/**
	 * 주어진 인자 중 null 또는 undefined 또는 빈 값이 존재하면 true 를 리턴
	 *
	 * @param _obj
	 */
	nullCheck: function (_obj) {
		const chk = (v) => {
			if (v == null || typeof v === 'undefined' || v === '') return true
		}
		if (_obj instanceof Array) {
			_.forEach(_.flattenDeep(_.cloneDeep(_obj), true), (v) => {
				if (chk(v)) return true
			})
		} else {
			if (chk(_obj)) return true
		}
		return false
	},
	
	/**
	 * OS 를 체크
	 */
	checkOS: function () {
		let OS = ''
		if (navigator.userAgent.toLowerCase().indexOf('ipad') !== -1 ||
			navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 ||
			navigator.userAgent.toLowerCase().indexOf('ipod') !== -1) {
			OS = 'iOS'
		} else if (navigator.userAgent.toLowerCase().indexOf('android') !== -1) {
			OS = 'Android'
		} else if ((navigator.userAgent).toLowerCase().indexOf('mac') !== -1) {
			OS = 'MacOS'
		} else if (('win16|win32|win64').indexOf(navigator.platform.toLowerCase()) !== -1 &&
			navigator.userAgent.toLowerCase().indexOf('android') === -1) {
			OS = 'Window'
		}
		return OS.toLowerCase()
	},
	
	/**
	 * 소수부 길이를 리턴
	 *
	 * @param _num
	 * @returns {number|number}
	 */
	getDecimalLength: function (_num) {
		return (_num + '').indexOf('.') !== -1 && !this.nullCheck(_num) ? _num.toString().split('.')[1].length || 0 : 0
	},
	
	/**
	 * 랜덤 곱셈 문제 생성
	 *
	 * @param _r1
	 * @param _r2
	 * _r1, _r2 : size 2 의 Array 또는 Number, size 2 의 배열로 받았을 때 첫 번째 배열의 요소 ~ 두 번째 배열의 요소 사이의 숫자 생성,
	 * Number 로 받았을 때 Number 길이의 숫자 생성
	 * @returns {(*|number|number)[]}
	 */
	createRandomQuiz: function (_r1, _r2) {
		let v1 = (_r1 instanceof Array) ? Math.floor(Math.random() * (_r1[1] - _r1[0] + 1)) + _r1[0] : Math.floor(Math.random() * (10 ** (_r1)))
		let v2 = (_r2 instanceof Array) ? Math.floor(Math.random() * (_r2[1] - _r2[0] + 1)) + _r2[0] : Math.floor(Math.random() * (10 ** (_r2)))
		return [v1, v2, v1 * v2]
	},
	
	/**
	 * 호스트 네임을 리턴
	 *
	 * @returns {boolean}
	 */
	isLocal: function () {
		return location.hostname === 'localhost'
	},
	
	/**
	 * local 환경일 때 로그를 표출
	 *
	 * @param _val
	 */
	localLog: function (..._val) {
		if (this.isLocal) console.log("::: LOCAL LOG ::: " + _val.toString().replaceAll(",", ", "))
	},
	
	/**
	 * local 환경일 때 주의를 표출
	 *
	 * @param _val
	 */
	localWarn: function (..._val) {
		if (this.isLocal) console.warn("::: LOCAL WARN ::: " + _val.toString().replaceAll(",", ", "))
	},
	
	/**
	 * local 환경일 때 에러를 표출
	 *
	 * @param _val
	 */
	localErr: function (..._val) {
		if (this.isLocal) console.error("::: LOCAL ERROR ::: " + _val.toString().replaceAll(",", ", "))
	},
	
	/**
	 * local 환경일 때 alert 표출
	 *
	 */
	localAlert: function (..._val) {
		if (this.isLocal) alert("::: LOCAL ALERT ::: " + _val.toString().replaceAll(",", ", "))
	},
	
	/**
	 * 현재 환경을 리턴 (App 사용 여부를 고려하여 리턴)
	 *
	 * @returns {string}
	 */
	getEnv: function () {
		if (typeof window.HWAPP !== 'undefined') return "ANDROID"
		else if (typeof webkit !== 'undefined') return "IOS"
		else return "WEB"
	},
	
	/**
	 * 환경에 따른 device token 수집 후 _selector 에 해당하는 HTMLElement 에 추가
	 *
	 * @param _selector
	 * @returns {null}
	 */
	deviceToken: function (_selector) {
		// eslint-disable-next-line no-undef
		try {
			if (this.getEnv === "ANDROID") window.HWAPP.getDeviceToken(_selector)
			// eslint-disable-next-line no-undef
			else if (this.getEnv === "IOS") webkit.messageHandlers.getDeviceToken.postMessage(_selector)
			else return null
		} catch (e) {
			this.localErr(e)
		}
	},
	
	/**
	 * null 을 빈 값으로 변환
	 *
	 * @param _obj
	 * @returns {string|*}
	 */
	nullToEmpty: function (_obj) {
		return (typeof _obj == 'undefined' || _obj == null) ? '' : _obj
	},
	
	/**
	 * 숫자형 변수인지 확인
	 *
	 * @param _obj
	 * @returns {boolean}
	 */
	isNumber: function (_obj) {
		return !isNaN(_obj * 1)
	},
	
	nl2br: function (str) {
		if (!str) {
			return ''
		}
		return str.replace(/\r\n|\n/g, '<br />')
	},
	
	/**
	 * 0 또는 null 또는 false 이면 false
	 *
	 * @param _obj
	 * @returns {boolean}
	 */
	parseBoolean: function (_obj) {
		return !(this.nullCheck(_obj) || _obj == 'false' || (_obj * 1) === 0)
	},
	
	/**
	 * _chkStr 이 null 또는 빈 값이면 _transStr 을 리턴, null 또는 빈 값이 아니면 _chkStr 을 리턴
	 *
	 * @param _chkStr
	 * @param _transStr
	 * @returns {*}
	 */
	transStringIfNull: function (_chkStr, _transStr) {
		return this.nullCheck(_chkStr) ? _transStr : _chkStr
	},
	
	/**
	 * _s 이상 _e 이하의 난수 생성
	 *
	 * @param _s
	 * @param _e
	 * @returns {*}
	 */
	createRandNum: function (_s, _e) {
		return Math.floor(Math.random() * (_e - _s + 1)) + _s
	},
	
	/**
	 * _parentString 에 _childString 이 포함되어 있는지
	 *
	 * @param _parentString
	 * @param _childString
	 * @param _isDeleteEmpty
	 * @returns {boolean|*}
	 */
	isContainString: function (_parentString, _childString, _isDeleteEmpty) {
		if (this.nullCheck(_childString)) return _parentString
		return _isDeleteEmpty ?
			_parentString.replaceAll(' ', '').indexOf(_childString.replaceAll(' ', '')) !== -1 :
			_parentString.indexOf(_childString) !== -1
	},
	
	/**
	 * _function 이 Function type 이면 _function 실행
	 *
	 * @param _function
	 */
	callFn: function (_function) {
		_function instanceof Function ? _function() : {}
	},
	
	/**
	 * 논리 연산자를 포함한 null check
	 *
	 * @param _logicalOperator
	 * AND 연산 parameter ::: "AND", "and", "&", "&&"
	 * 모든 요소가 null 이면 true 를 리턴
	 *
	 * OR 연산 parameter ::: "OR", "or", "|", "||"
	 * 하나 이상의 요소가 null 이면 true 를 리턴
	 *
	 * XOR 연산 parameter ::: "XOR", "xor"
	 * 모든 요소가 null 이거나 모든 요소가 null 이 아니면 true 를 리턴
	 * @param _obj
	 * @returns {*}
	 */
	nullCheckWithLogicalOperator: function (_logicalOperator, ..._obj) {
		_logicalOperator = _logicalOperator.toLowerCase().replaceAll(' ', '')
		let _finalLogicalOperator = this.isContainString("and &&", _logicalOperator) ? "and" :
			this.isContainString("or ||", _logicalOperator) ? "or" :
				this.isContainString("xor", _logicalOperator) ? "xor" : {}
		let result = []
		_.forEach(_obj, (v, i) => {
			_finalLogicalOperator === "and" ? result === [] ? result = this.nullCheck(v) : result &= this.nullCheck(v) :
				_finalLogicalOperator === "or" ? result === [] ? result = this.nullCheck(v) : result |= this.nullCheck(v) :
					_finalLogicalOperator === "xor" ? result[i] = this.nullCheck(v) : {}
		})
		if (_finalLogicalOperator === "xor") result = ArrayUtils.isEqualAllItemInArray(result)
		return this.parseBoolean(result)
	},
	
	parseHTMLElement: function (_str) {
		return new DOMParser().parseFromString(_str, 'text/html').querySelector('body').childNodes[0]
	},
	
	/**
	 * {Key: Value} 오브젝트에서 value 를 통해 key 를 리턴
	 *
	 * @param _obj
	 * @param _val
	 * @returns {string}
	 */
	getKeyByValue(_obj, _val) {
		return Object.keys(_obj).find(key => _obj[key] === _val)
	},
	
	/**
	 * 파라미터 노트를 포함한 모든 자식 노드 선택
	 *
	 * @param _jqObj
	 * @returns {Array<Flat<unknown>>}
	 */
	getAllChildNodes(_jqObj) {
		let targetNode
		
		if (_jqObj instanceof jQuery) targetNode = _jqObj
		else targetNode = $(_jqObj)
		
		let nodeArr = []
		nodeArr.push(_.flattenDeep(targetNode))
		
		while (targetNode.children().length > 0) {
			targetNode = targetNode.children()
			nodeArr.push(_.flattenDeep(targetNode))
		}
		
		return _.flattenDeep(nodeArr)
	},
	
	/**
	 * Object.key Getter 시 Object.key 가 null 이면 빈 값을 리턴해주는 Object 로 변환
	 * @param _obj 변환할 Object
	 */
	toSafeObj(_obj) {
		if (!(_obj instanceof Object)) return
		_obj.safe = (_key) => {return _obj[_key] == null ? '' : _obj[_key]}
	}
	
}
