import _ from 'lodash'

export default {

	shuffle: function(array) {
		array.sort(() => Math.random() - 0.5);
	},

	/**
	 * _size 인자의 길이에 해당하는 Array 를 생성해서 리턴
	 *
	 * @param _size
	 * Ex) [2, 2] => Dimension 1 size : 2, Dimension 2 size : 2
	 * @param _defaultVal
	 * @returns {*[]|any[][]}
	 */
	createArr: function (_size, _defaultVal) {
		return _size.length === 1 ? Array.from({length: _size[0]}, () => _defaultVal) : Array.from(Array(_size[0]), () => Array(_size[1]).fill(_defaultVal))
	},

	/**
	 * _arr 에서 _val 개수를 리턴
	 *
	 * @param _arr
	 * @param _val
	 * @returns {*}
	 */
	getValueAmountInArray: function (_arr, _val) {
		return _arr.filter(i => i === _val).length
	},

	/**
	 * _arr 의 모든 값이 일치하는지 체크
	 *
	 * @param _arr
	 * @returns {*}
	 */
	isEqualAllItemInArray: function (_arr) {
		return _arr.every((val, i, arr) => val === arr[0])
	},

	/**
	 * 배열을 작은순으로 정렬
	 *
	 * @param _arr
	 * @returns {*}
	 */
	orderByDescArray: function (_arr) {
		return _arr.sort(function (a, b) {
			return a - b
		})
	},

	/**
	 * _arr 의 _val 를 제거 후 리턴
	 *
	 * @param _arr
	 * @param _val
	 * @returns {*}
	 */
	removeValueInArray: function (_arr, _val) {
		let i = 0
		while (i < _arr.length) {
			if (_arr[i] === _val) {
				_arr.splice(i, 1)
			} else {
				++i
			}
		}
		return _arr
	},

	/**
	 * 두 배열이 같은지 체크
	 *
	 * @param _arr1
	 * @param _arr2
	 * @returns {boolean}
	 */
	isEqArray: function (_arr1, _arr2) {
		return JSON.stringify(_arr1) === JSON.stringify(_arr2)
	},

	/**
	 * 각 _arr 의 길이가 같은지 체크
	 *
	 * @param _isFlatten
	 * Ex) true => 배열을 펼친 후 체크
	 * @param _arr
	 * @returns {boolean}
	 */
	isEqArrayLength: function (_isFlatten, ..._arr) {
		let _len = _isFlatten ? _.flattenDeep(_.cloneDeep(_arr[0])).length : _arr[0].length
		for (let i = 1; i < _arr.length - 1; i++) {
			if (_isFlatten ? _.flattenDeep(_.cloneDeep(_arr[i])).length !== _len : _arr[i].length !== _len) return false
		}
		return true
	},

	/**
	 * 두 배열을 병합하여 리턴
	 *
	 * @param _arr1
	 * @param _arr2
	 * @returns {*}
	 */
	concatArray: function (_arr1, _arr2) {
		let res = new (_arr1.constructor)(_arr1.length + _arr2.length)
		res.set(_arr1, 0)
		res.set(_arr2, _arr1.length)
		return res
	},

	/**
	 * _len 길이의 난수 배열을 생성
	 *
	 * @param _len
	 * @param _isAllowDuplicates
	 * @returns {*[]}
	 */
	createRandNumArr: function (_len, _isAllowDuplicates) {
		let res = new Array(0)
		let i = 0
		while (i < _len) {
			let randNum = Math.round(Math.random() * (_len - 1))
			if (res.indexOf(randNum) === -1 || _isAllowDuplicates) {
				res.push(randNum)
				++i
			}
		}
		return res
	},

	/**
	 * _arr2 를 _arr1 의 형태로 재구성하여 리턴
	 * Ex) _arr1 = [1, a, [1, q, 3], 1, [w, 2]], _arr 2 = [q, 1, 3, 5, a, 7, q, d]
	 *     return [q, 1, [3, 5, a], 7, [q, d]]
	 * @param _arr1
	 * @param _arr2
	 * @returns {*}
	 */
	reshapeArr: function (_arr1, _arr2) {
		_.forEach(_arr1, (v, i) => {
			if (v instanceof Array && v.length !== 1) {
				_arr2[i] = _.flattenDeep(_.cloneDeep(_arr2).splice(i, v.length))
				_arr2.splice(i + 1, v.length - 1)
			}
		})
		return _arr2
	},

	/**
	 * 1차원 배열인지 체크
	 *
	 * @param _arr
	 * @returns {boolean}
	 */
	is1DimArr: function (_arr) {
		return this.isEqArray(_.flattenDeep(_.cloneDeep(_arr)), _arr)
	}
}