import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: "history",
	// pageRouting: true,
	// base: process.env.BASE_URL,
	routes: [

		{
			// 로그인 페이지
			name: 'login',
			path: '/login',
			components: {
				'rv-container': () => import("@/components/accounts/login")
			}
		},

		{
			// sns 로그인 페이지
			name: 'snsJoin',
			path: '/snsJoin',
			components: {
				'rv-container': () => import("@/components/accounts/snsJoin")
			}
		},

		{
			// 이메일 회원가입 페이지
			name: 'emailJoin',
			path: '/emailJoin',
			components: {
				'rv-container': () => import("@/components/accounts/join/emailJoin")
			}
		},

		{
			// 이메일 회원가입 후 승인 대기 페이지
			name: 'emailJoinAuth',
			path: '/emailJoinAuth',
			components: {
				'rv-container': () => import("@/components/accounts/join/emailJoinAuth")
			}
		},
		{
			// 이메일 회원가입 후 승인 완료 페이지
			name: 'emailJoinDone',
			path: '/emailJoinDone',
			components: {
				'rv-container': () => import("@/components/accounts/join/emailJoinDone")
			}
		},
		{
			// 비밀번호 찾기 페이지
			name: 'findPasswd',
			path: '/findPasswd',
			components: {
				'rv-container': () => import("@/components/accounts/find/findPasswd")
			}
		},
		{
			// 카카오 REST API 로그인 리턴 페이지
			name: 'kakaoRestReturn',
			path: '/snsJoin/kakaoRestReturn',
			components: {
				'rv-container': () => import("@/components/accounts/sns/kakaoRestReturn")
			}
		},
		{
			// 네이버 로그인 API 로그인 리턴 페이지
			name: 'naverReturn',
			path: '/snsJoin/naverReturn',
			components: {
				'rv-container': () => import("@/components/accounts/sns/naverReturn")
			}
		},
		{
			// 네이버 REST API 로그인 리턴 페이지
			name: 'naverRestReturn',
			path: '/snsJoin/naverRestReturn',
			components: {
				'rv-container': () => import("@/components/accounts/sns/naverRestReturn")
			}
		},
		{
			// 프로필 선택 페이지
			name: 'profile',
			path: '/profiles/',
			components: {
				'rv-container': () => import("@/components/accounts/profile/profileSelect")
			}
		},
		{
			// 프로필 가입 페이지
			name: 'profileJoin',
			path: '/profileJoin',
			components: {
				'rv-container': () => import("@/components/accounts/profile/profileJoin")
			}
		},
		{
			// 프로필 승인 대기 페이지
			name: 'profileAuth',
			path: '/profileAuth',
			components: {
				'rv-container': () => import("@/components/accounts/profile/profileAuth")
			}
		},
		{
			// 계정 관리 페이지
			name: 'accountMyPage',
			path: '/accountMyPage',
			components: {
				'rv-container': () => import("@/components/accounts/myPage/accountMyPage")
			}
		},
		{
			// 프로필 관리 페이지
			name: 'profileMyPage',
			path: '/profileMyPage',
			components: {
				'rv-container': () => import("@/components/accounts/myPage/profileMyPage")
			}
		},
		{
			// 프로필 목록 관리 페이지
			name: 'profileManage',
			path: '/profileManage',
			components: {
				'rv-container': () => import("@/components/accounts/myPage/profileManage")
			}
		},
		{
			// 탈퇴 확인 페이지
			name: 'confirmOut',
			path: '/confirmOut',
			components: {
				'rv-container': () => import("@/components/accounts/myPage/confirmOut")
			}
		},
		{
			// 출석 페이지
			name: 'attendance',
			path: '/attendance',
			components: {
				'rv-container': () => import("@/components/education/attendance")
			}
		},








		{
			// 교과정 페이지 : 교육 메인
			name: 'courseList',
			path: '/',
			components: {
				'rv-container': () => import("@/components/education/courseList")
			}
		},
		{
			// 교육 메인 페이지
			name: 'mainList',
			path: '/mainList/',
			components: {
				'rv-container': () => import("@/components/education/mainList")
			}
		},
		{
			// 교육 컨텐츠 페이지
			name: 'contentList',
			path: '/contentList/',
			components: {
				'rv-container': () => import("@/components/education/contentList")
			}
		},
		{
			// 교육 진행 페이지
			name: 'proceed',
			path: '/proceed/:emIdx/:userIdEnc/',
			components: {
				'rv-container': () => import("@/components/education/answer/eduProceed")
			}
		},
		{
			// 교육 진행 페이지
			name: 'answerComplete',
			path: '/answerComplete/:eaIdx/:userIdEnc/',
			components: {
				'rv-container': () => import("@/components/education/answer/answerComplete")
			}
		},






		{
			// 펀파닉스 페이지 : 펀파닉스 메인
			name: 'phonics',
			path: '/phonics/',
			components: {
				'rv-container': () => import("@/components/education/phonics/phonicsList")
			}
		},
		{
			// 펀파닉스 컨텐츠 페이지
			name: 'phonics-contents',
			path: '/phonics/contentList/',
			components: {
				'rv-container': () => import("@/components/education/phonics/contentList")
			}
		},






		{
			// 학습현황
			name: 'myEducation',
			path: '/education/mine/myEducation',
			components: {
				'rv-container': () => import("@/components/education/mine/myEducation")
			}
		},
		{
			// 칭찬스티커
			name: 'mySticker',
			path: '/education/mine/mySticker',
			components: {
				'rv-container': () => import("@/components/education/mine/mySticker")
			}
		},
		{
			// 칭찬스티커
			name: 'stickerMsg',
			path: '/education/mine/stickerMsg',
			components: {
				'rv-container': () => import("@/components/education/mine/stickerMsg")
			}
		},







		{
			// 로딩
			name: 'loading',
			path: '/loading',
			components: {
				'rv-container': () => import("@/components/loading")
			}
		},

        {
            // 정적페이지 표출
            path: '/statics/:PAGE_CODE',
            components: {
                'rv-container': () => import('@/components/statics/appPage')
            },
        },










		{
			// dnd test
			name: 'dndTest',
			path: '/dndTest',
			components: {
				'rv-container': () => import("@/components/education/answer/dndTest")
			}
		},





		{
			// 정적 페이지 연결
			path: '/pages/:PAGE_CODE',
			components: {
				'rv-container': () => import('@/components/pages/privacypolicy')
			},
		},


		{
			// 가이드 페이지
			name: 'guide',
			path: '/guide',
			components: {
				'rv-container': () => import('@/pages/guide')
			},
		},


	]
});
