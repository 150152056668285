import Vue from 'vue'


/**
 * 현재수와 합수로 평균 도출
 */
Vue.filter("avg", function (entry, sum) {

	return Math.ceil(entry / sum * 100);
})


Vue.filter("currency", (value) => {
	if (typeof value === 'undefined') {
		return
	}
	return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
});



Vue.filter("slice1to10", function (entry) {

	const seq1 = entry.toString().substr( entry.toString().length - 1)
	if ( parseInt(seq1) > 0 ) {
		return seq1.padStart(2, '0');
	} else {
		return "10";
	}
})

// /**
//  * 세 자리 마다 콤마
//  */
// Vue.filter("comma", (value) => {
// 	if (typeof value === 'undefined') {
// 		return
// 	}
// 	return value.toLocaleString('ko-KR');
// 	// return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// })
