export default {

	methods : {

		OrderInfoVO( $_session ) {
			this.deliveryZonecode = '';     // 우편번호
			this.email = '';
			this.oid = '';
			this.userId = ($_session != null) ? $_session.coEmailId : "";  //id
			this.orderName = '';    // 주문건 명
			this.orderCount = '';   // 주문 상품 종류수
			this.orderCorp = '';    // CHAR '결제PG',
			this.orderPrice = 0;    //INT NULL COMMENT '주문금액',
			this.deliveryPrice = 0; //INT NULL COMMENT '배송금액 따로 저장',
			this.usePoint = 0;      //INT NULL COMMENT '포인트 사용금액',
			this.stat = 1;          //CHAR(1) NULL COMMENT 상태값-숫자
			this.statText = '';     //주문상태 Text '주문 상태 : 주문(결제확인중) (1), 배송준비중(결제확인) (2) , 배송중 (3), 배송완료 (4), 주문취소 (5) , 반품 (6_',
			this.statCase = '';     //CHAR(1) NULL COMMENT '개별 주문 상태 ',
			this.paidType = '';     //CHAR(1) NULL COMMENT '결제 방법 : 계좌이체(B), 무통장입금(D), 신용카드(C), 카카오페이(K), 네이버페이(N)',
			this.orderMemo = '';    //VARCHAR(255) NULL COMMENT '주문시 요구사항',
			this.receipt = '';      //CHAR(1) NULL COMMENT '영수증 발행 유무',
			this.deliveryAddress1 = '';     //'주소 1 (시/도 구/군 도로명 주소)',
			this.deliveryAddress2 = '';     //주소2 상세주소
			this.deliveryReceiverName = '';     // 수령인 이름
			this.deliveryReceiverPhone = '';    // 수령인 연락처
			this.deliveryZipcode = '';      // 수령인 우편번호
			this.deliveryMemo = '';         // 배송 메모
			this.returnCode = '';           // 취소/반품교환 코드
		},

	}

}