export default {

	setOnlyNum: function (name) {
		var valueByName = $('input[name='+name+']').val().replace(/[^0-9]/g,'');
		$('input[name='+name+']').val(valueByName);
	},


	toPercent (_num, _sum) {
		return Math.floor((_num / _sum) * 100);
	},


	randNum (min, max) {
		max++;
		return Math.floor( Math.random() * (max - min) + min );
	},


}