import $ from 'jquery'

let _BASE_PROTOCOL = location.protocol;
let _BASE_HOST = location.hostname;
let _BASE_PORT = location.port;

const INNER_HOST = "192.168.0.219";
if ( _BASE_HOST === "localhost" || _BASE_HOST === "127.0.0.1" || _BASE_HOST === INNER_HOST ) {
	_BASE_PROTOCOL = "http:";
	_BASE_HOST = "localhost";
	_BASE_PORT = "8080";

	// LOCAL 환경
	// _BASE_HOST = INNER_HOST;
}
// alert( _BASE_HOST );

let _API_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
let _CMS_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
if ( $.trim(_BASE_PORT) !== "" ) {
	_API_URL += ":" + _BASE_PORT;
	_CMS_URL += ":" + _BASE_PORT;
}
_API_URL += "/api";
_CMS_URL += "/hwmanager";

let _BASE_URL = _BASE_PROTOCOL + "//" + _BASE_HOST;
if ( location.port !== '' ) {
	_BASE_URL += ":" + location.port;
}
// _BASE_URL = "http://192.168.0.219:8081";
// _API_URL = "https://www.masha-bear.co.kr/api"

// console.log(_BASE_PROTOCOL)
// console.log(_BASE_HOST)
// console.log(_BASE_URL)
// console.log(_BASE_PORT)
// console.log(_API_URL)

export default {

	FRONT_URL : _BASE_URL,
	BASE_URL : _BASE_URL,
	API_URL : _API_URL,
	_CMS_URL : _CMS_URL,

	KAKAO_LOGIN_KEY : "ea99227d8985d7a16192eaf76300b700",
	KAKAO_LOGIN_KEY_REST : "acd7b3d5f2422164ebd94bb2dee9c8f3",
	KAKAO_LOGIN_RETURN_REST : _BASE_URL + "/snsJoin/kakaoRestReturn",

	NAVER_LOGIN_KEY : "ScuP9hb_scuIH7q98wEG",
	NAVER_LOGIN_SECRET : "DKZJ43zpu_",
	NAVER_LOGIN_RETURN : _BASE_URL + "/snsJoin/naverReturn",
	NAVER_LOGIN_RETURN_REST : _BASE_URL + "/snsJoin/naverRestReturn",
	NAVER_LOGIN_DOMAIN: '',

	APPLE_CLIENT_ID : "kr.co.mashabear.web",
	APPLE_LOGIN_RETURN : "https://www.masha-bear.co.kr",

	// 공통 및 코드
	COMMON_CODE: _API_URL + "/commons/code",
	DP_KEYWORD: _API_URL + "/keywords",


	// DISPLAY + CACHE
	DISPLAY: _API_URL + "/display",
	DISPLAY_CMS_POPUP: _CMS_URL + "/popup",
	DISPLAY_POPUP: _API_URL + "/display/popup",


	// 로그인 및 세션
	SESSION: _API_URL + "/session",


	// SNS 관련
	SNS_APPLE: _API_URL + "/sns/apple",


	// 회사
	CO_INFO: _API_URL + "/coinfos",

	// 회원가입
	CO_ACCOUNT: _API_URL + "/coaccounts",
	CO_PROFILE: _API_URL + "/profile",
	CO_ATTENDANCE: _API_URL + "/coaccounts/attendance",
	CO_RECODE: _API_URL + "/coaccounts/records",

	// 파일 공통
	FILES: _API_URL + "/file",
	FILES_SHOW: _API_URL + "/file/show/",
	FILES_DOWN: _API_URL + "/file/download/",
	FILES_PLAY: _API_URL + "/file/play/",

	// 게시판
	BOARDS: _API_URL + "/boards",
	BOARD_COMMENTS: _API_URL + "/boards/comments",
	BOARD_LIKE: _API_URL + "/boards/like",
	BOARD_CAUTION: _API_URL + "/boards/caution",
	BOARD_FILES: _API_URL + "/boards/file",
	BOARD_FILES_SHOW: _API_URL + "/boards/file/show/",
	BOARD_FILES_DOWN: _API_URL + "/boards/file/download/",
	BOARD_FILES_PLAY: _API_URL + "/boards/file/play/",


	// SHOP
	SHOP_GOODS: _API_URL + "/goods",
	SHOP_ORDER: _API_URL + "/order",
	SHOP_PG: _API_URL + "/pg",
	SHOP_FILE_SHOW: _API_URL + "/shop/file/show/",
	SHOP_FILES_DOWN: _API_URL + "/shop/file/download/",


	// 교육
	EDU_COURSE: _API_URL + "/edu/course",
	EDU_MAIN: _API_URL + "/edu/main",
	EDU_STUDENT: _API_URL + "/edu/student",
	EDU_CONTENT: _API_URL + "/edu/content",
	EDU_QUESTION: _API_URL + "/edu/question",
	EDU_ANSWER: _API_URL + "/edu/answer",
	EDU_MINE: _API_URL + "/edu/mine",
	EDU_REC: _API_URL + "/edu/record",
	EDU_FILE: _API_URL + "/edu/file",
	EDU_FILE_SHOW: _API_URL + "/edu/file/show/",
	EDU_FILE_DOWN: _API_URL + "/edu/file/download/",
	EDU_FILE_PLAY: _API_URL + "/edu/file/play/",


	// 정적 페이지
	STATIC_PAGE: _API_URL + "/pages/",


	// 내정보
	MINE: _API_URL + '/mypage/info',
	CART: _API_URL + '/mypage/cart',

	OAUTH: _API_URL + '/oauth'


}
//export default APIs;
