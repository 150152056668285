

export default {

	created() {

	},
	mounted() {

	},
	methods: {




		appDevice () {

			console.log(typeof window.HWAPP)
			if ( typeof window.HWAPP !== 'undefined' ) {
				// alert( "typeof window.HWAPP ::::: " +  typeof window.HWAPP );
				return "ANDROID";
			}

			console.log(typeof webkit)
			if ( typeof webkit !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('safari') === -1 ) {
				// alert( "typeof webkit ::::: " +  typeof webkit );
				return "IOS";
			}

			return "WEB";
		},
		
		appMailTo(_email) {
			
			let a = document.createElement("a")
			a.href = "mailto:" + _email
			
			switch (this.appDevice()) {
				case "ANDROID":
					a.click()
					break
				case "IOS":
					this.IOSMailTo(_email)
					break
				case "WEB":
					a.click()
					break
				default:
					a.click()
					break
			}
		},
		
		IOSMailTo(_email) {
			let params = {
				email: _email
			}
			try {
				if ( typeof webkit !== 'undefined') {
					webkit.messageHandlers.iosMailTo.postMessage(params);
				}
			} catch(err) {
				console.log(err);
			}
		},

		/**
		 * 장치 토큰 조회
		 */
		appDeviceToken (_dom) {

			switch ( this.appDevice() ) {
				case "ANDROID":
					this.getAndroidDeviceToken( _dom );
					break;
				case "IOS":
					this.getIOSDeviceToken( _dom )
					break;
				case "WEB":
					break;
				default:
					break;
			}

		},


		/**
		 * 앱 연결
		 * @param _obj
		 */
		appOpen ( _obj ) {

			switch ( this.appDevice() ) {
				case "ANDROID":
					if ( _obj.noANDROID == null || !_obj.noANDROID ) {
						this.androidOpen( _obj );
					}
					break;
				case "IOS":
					if ( _obj.noIOS == null || !_obj.noIOS ) {
						this.iosOpen( _obj )
					}
					break;
				case "WEB":
					if ( _obj.noWEB == null || !_obj.noWEB ) {
						// let winOpen = window.open();
						// winOpen.location = _obj.url
						window.open( _obj.url, _obj.target, _obj.options )
					}
					break;
				default:
					if ( _obj.noWEB == null || !_obj.noWEB ) {
						// let winOpen = window.open();
						// winOpen.location = _obj.url
						window.open( _obj.url, _obj.target, _obj.options )
					}
					break;
			}

		},


		/**
		 * 앱 닫기
		 * @param _obj
		 */
		appClose ( _obj ) {

			switch ( this.appDevice() ) {
				case "ANDROID":
					this.androidClose( _obj );
					break;
				case "IOS":
					this.iosClose( _obj )
					break;
				case "WEB":
					if (  _obj.reload && opener != null ) {
						opener.location.reload();
					}
					if (  _obj.url && opener != null ) {
						opener.location.href = _obj.url;
					}
					window.close();
					break;
				default:
					if ( _obj.reload && opener != null ) {
						opener.location.reload();
					}
					if (  _obj.url && opener != null ) {
						opener.location.href = _obj.url;
					}
					window.close();
					break;
			}

		},


		/**
		 * 앱 데이터 전달
		 * @param _obj
		 */
		appPost ( _obj ) {

			switch ( this.appDevice() ) {
				case "ANDROID":
					this.androidPost( _obj );
					break;
				case "IOS":
					this.iosPost( _obj )
					break;
			}

		},




		/**
		 * Android 디바이스 토큰 조회
		 */
		getAndroidDeviceToken ( _dom ) {
			try {
				if ( window.HWAPP != null ) {
					window.HWAPP.getDeviceToken(_dom);
				}
			} catch (err) {
				console.log(err);
			}
		},

		/**
		 * 안드로이드 새창 연결
		 * @param _obj
		 */
		androidOpen ( _obj ) {
			try {
				window.HWAPP.androidOpen( JSON.stringify( _obj ) );
			} catch(err) {
				console.log(err);
			}
		},

		/**
		 * 안드로이드 새창 닫기
		 * @param _obj
		 */
		androidClose ( _obj ) {
			try {
				window.HWAPP.androidClose( JSON.stringify( _obj ) );
			} catch(err) {
				console.log(err);
			}
		},


		/**
		 * 안드로이드 데이터 전달
		 * @param _obj
		 */
		androidPost ( _obj ) {
			try {
				window.HWAPP.androidClose( JSON.stringify( _obj ) );
			} catch(err) {
				console.log(err);
			}
		},







		/**
		 * iOS 디바이스 토큰 조회
		 */
		getIOSDeviceToken ( _dom ) {
			try {
				webkit.messageHandlers.getDeviceToken.postMessage(_dom);
			} catch(err) {
				console.log(err);
			}
		},


		/**
		 * IOS 새창연걸
		 */
		iosOpen ( json ) {
			try {
				if ( typeof webkit !== 'undefined') {
					webkit.messageHandlers.iosOpen.postMessage(json);
				}
			} catch(err) {
				console.log(err);
			}
		},


		iosClose () {
			try {
				webkit.messageHandlers.iosClose.postMessage('');
			} catch(err) {
				console.log(err);
			}
		},

		iosPost ( _data ) {
			try {
				webkit.messageHandlers.iosPost.postMessage( _data );
			} catch(err) {
				console.log(err);
			}
		},



		/**
		 * * 모바일인지 여부 *
		 * wind16: Windows 16bit
		 * * wind32: Windows 32bit
		 * * wind64: Windows 64bit
		 * * MacIntel: Intel CPU의 Mac
		 * * Mac: Mac
		 * * @return true:모바일/false:PC */

		isMobileYn() {
			var filter = "win16|win32|win64|mac|macintel";
			if (navigator.platform ) {
				if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
					return true;
				} else {
					return false;
				}
			} return false;
		}



	}


}