import $ from 'jquery'
import APIs from '@/commons/APIs'

const CodeUtils = {

	code2Select (_tagId, _code, _isFirst = false ) {

		$.ajax({
			url : APIs.COMMON_CODE + "/getCodeTree",
			data : {
				code : _code
			},
			async : false,
			success: function (result) {
				if ( result.status === "ok" ) {

					console.log( result )
					$(_tagId + ' option').remove();

					var options = (_isFirst !== false) ? "<option value='" + _isFirst.value + "'>" + _isFirst.text + "</option>" : "";
					$.each( result.codeList, function (k, v) {
						options += "<option value='" + v.code + "'>" + v.codeName + "</option>";
					});
					$("select" + _tagId).append( options );
				}
			}
		})
	},


	codeNode2Select (_tagId, _code, _isFirst = false, _valField = null ) {

		$.ajax({
			url : APIs.COMMON_CODE + "/getCodeTree",
			data : {
				code : _code
			},
			async : false,
			success: function (result) {

				console.log( result )
				if ( result.status === "ok" ) {
					$(_tagId + ' option').remove();
					var options = ( (_isFirst !== false) ? "<option value='" + _isFirst.value + "'>" + _isFirst.text + "</option>" : "" ) + this.createOptions(0,  result.codeList, _valField )
					$("select" + _tagId).append( options );
				}
			}
		})
	},



	createOptions ( _indent, _codeList, _valField = null ) {

		var optionsList = "";
		$.each( _codeList, function (k, v) {

			var icon = "";
			for (var i = 0; i < _indent; i++) {
				icon += "&nbsp; &nbsp; &nbsp; &nbsp;"
				if ( i + 1 < _indent ) {
					// if ( _codeList.length > k + 1 ) {
					icon += "│";
					// }
				} else {
					if ( _codeList.length > k + 1 || v.rows.length > 0 ) {
						icon += "┞";   // ├┣┠┝┞ 79517
					} else {
						icon += "┗";
					}
				}
			}

			if ( _valField == null ) {
				optionsList += "<option value='" + v.code + "'>" + ( icon ) + " " + v.codeName + "</option>";
			} else {
				optionsList += "<option value='" + v[_valField] + "'>" + ( icon ) + " " + v.codeName + "</option>";
			}

			if ( v.rows.length > 0 ) {
				optionsList += this.createOptions( _indent+1, v.rows, _valField );
			}
		});
		return optionsList;
	},


}
export default CodeUtils;