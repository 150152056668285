
import webUtils from '@/commons/utils/web.utils'
import axiosCaller from '@/commons/utils/axios.caller'
import APIs from '@/commons/APIs'

export default {

	methods: {


		CoInfo() {

			this.cidx;
			this.bizNumber;
			this.coCode;
			this.coName;
			this.ceoName;
			this.csName;
			this.csTel;
			this.csEmail;
			this.zipcode;
			this.address1;
			this.address2;

			this.isIosShop;
			this.isIosSns;

			this.coAccountList = [];

		},


		CoAccountVO() {

			this.caidx = 0;
			this.coCode = webUtils.getSubdomain();
			this.coEmailId;
			this.passwd;
			this.passchk;
			this.emailVerify = false;
			this.companyName;
			this.userName;
			this.nickName;
			this.enableMember = true;
			this.rateCode;
			this.teamCode;

			this.phone;

			this.zipcode;
			this.address1;
			this.address2;

			this.authEmployee = false;

			this.deviceToken; //디바이스 키 sample 255자

			this.coInfoVO = {};

			this.coEmailIdDec;
			this.userNameDec;

			this.isUseTerms = false;
			this.isPrivacyPolicy = false;
			this.isMarketing = false;

			this.isSnsLogin = false;
			this.snsService;
			this.snsId;

			this.isParent = false;
			this.parentId;

			this.profileSrcs;

		},


		async getCoInfoVO() {

			return await new Promise( (resolve, reject) => {

				const subDomain = webUtils.getSubdomain();
				this.axiosCaller.get( this,  this.APIs.CO_INFO + "/" + subDomain, {
					coCode: subDomain
				}, (res) => {
					let result = res.data
					if ( result.status === "ok" ) {
						resolve( result.coInfoVO )
					} else {
						reject(res)
					}
				})

			})

		},


	}

}