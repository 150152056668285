

import $ from 'jquery'
const PAGE_SIZE = 10;


import webUtils from '@/commons/utils/web.utils.js'

const PageUtils = {


	pushState: (_params) => {
		history.pushState( _params, null, location.pathname + "?" + webUtils.serialize(_params) )
	},


	routerPush: ($this, _params) => {

		delete _params.LOGIN_ID
		delete _params.LOGIN_NICK
		delete _params.JSESSIONID

		$this.$router.push({
			query: _params,
		}).catch(() => {})
	},



	getDefuletSort: ( _defaultSort ) => {
		if( _defaultSort === undefined || _defaultSort == null || _defaultSort === "") {
			_defaultSort = 'createDate,DESC'
		}
		return _defaultSort;
	},


	Pageable: (_defaultSort) => {

		_defaultSort = PageUtils.getDefuletSort(_defaultSort);

		var temp = {};
		temp.page = webUtils.getParameterInt("page")
		temp.size = (webUtils.getParameterInt("size") === 0) ? PAGE_SIZE : webUtils.getParameterInt("size");
		temp.sort = webUtils.getParameterInt("sort") === "" || webUtils.getParameterInt("sort") == null ? _defaultSort : decodeURIComponent(webUtils.getParameter("sort"))
		temp.totalCount = 0;
		return temp;
	},



	Params: (_defaultSort) => {

		_defaultSort = PageUtils.getDefuletSort(_defaultSort);

		var temp = {};
		temp.MODE = (webUtils.getParameter("MODE") === "") ? "LIST" : webUtils.getParameter("MODE");

		temp.page = webUtils.getParameterInt("page")
		temp.size = (webUtils.getParameterInt("size") === 0) ? PAGE_SIZE : webUtils.getParameterInt("size");
		temp.sort = webUtils.getParameter("sort") === "" || webUtils.getParameter("sort") == null ? _defaultSort : decodeURIComponent(webUtils.getParameter("sort"))
		//temp.totalCount = 0;

		temp.searchKey = webUtils.getParameter("searchKey") === "" || webUtils.getParameter("searchKey") == null ? "" : decodeURIComponent(webUtils.getParameter("searchKey"))
		temp.searchText = webUtils.getParameter("searchText") === "" || webUtils.getParameter("searchText") == null ? "" : decodeURIComponent(webUtils.getParameter("searchText"))
		return temp;
	},


	/*function setPageable(  _pageagle ) {
		return {
			page: _pageagle.pageNumber,
			size: _pageagle.pageSize,
			// sort: _pageagle.sort,
		}
	}*/



	doSort: function ( pageable, colName) {

		if ( pageable.sort.split(',')[0] !== colName ) {
			pageable.sort = colName + ',DESC'
			$("span[id^=i-]").removeClass('sort-asc sort-desc sort-none').addClass('sort-none')
			$('#i-'+colName).addClass("sort-desc")
			return pageable.sort
		} else {

			if ( pageable.sort.split(',')[1] === 'DESC' ){
				pageable.sort = colName + ',ASC'

				// $("span[class^=sort-]").removeClass()
				$("span[id^=i-]").removeClass('sort-asc sort-desc sort-none').addClass('sort-none')

				$('#i-'+colName).addClass("sort-asc")
			}else{
				pageable.sort = colName + ',DESC'
				$("span[id^=i-]").removeClass('sort-asc sort-desc sort-none').addClass('sort-none')
				$('#i-'+colName).addClass("sort-desc")
			}

		}

		return pageable.sort
	},





	setSearch: function (_param) {

		var pageable = new this.Pageable();
		pageable.page = 0
		pageable.sort = "createDate,DESC"
		pageable.size = _param.size

		return pageable;
	},


}
export default PageUtils;







