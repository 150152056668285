import stringUtils from "@/commons/utils/string.utils";
import Qs from "qs";
import APIs from '@/commons/APIs'
import commonUtils from "@/commons/utils/common.utils";


let SESSION_MAIN_KEY = "HWSAccount";
let SESSION_KEY = "HWSSession";
let SESSION_DATE = "SyncDate";
let TIMEOUT = (1000 * 60) * 20;

export default {

    data() {
        return {
            SESSION_KEY: APIs.SESSION_KEY,
            SESSION_DATE: {},
        }
    },

    created() {

        const self = this

        console.log(this.$session.getAll())

        if (this.getSessionProc() !== undefined) {
            this.$session.set(SESSION_DATE, new Date())
        }

    },

    methods: {

        /**
         * VUE 세션 ID 조회
         * @returns {null|*}
         */
        getVueSessionID() {
            return this.$session.get("session-id")
        },


        isMine(_writerKey) {
            if (this.isLogin()) {
                const userId = this.$session.get(SESSION_KEY).coEmailId
                if (userId !== undefined && userId === _writerKey) {
                    return true;
                }
            }

            return false;
        },


        /**
         * 로그인 페이지로 이동
         */
        goLogin() {

            let params = (!this.stringUtils.isEmpty(Qs.stringify(this.$route.query))) ? "?" + Qs.stringify(this.$route.query) : "";
            let pathname = location.pathname;
            let referrer = (this.stringUtils.isEmpty(params)) ? "?referrer=" + encodeURIComponent(pathname) : "?referrer=" + encodeURIComponent(pathname + params);
            location.href = "/member/login" + referrer;
            /*this.$router.push({
                name: "login"
            })*/
        },


        /**
         * 현재 페이지 로그인 페이지로 대체
         */
        resetLogin() {

            this.$router.replace({
                name: "login"
            })
        },


        /**
         * 현재 로그인 세션 조회
         * @returns {*}
         */
        getSessionProc() {
            //console.log( this.$session.get(SESSION_KEY) )
            if (this.isLogin()) {
                return this.$session.get(SESSION_KEY);
            }
            return null;
        },

        getMainSessionProc() {
            //console.log( this.$session.get(SESSION_KEY) )
            if (this.isLoginMain()) {
                return this.$session.get(SESSION_MAIN_KEY);
            }
            return null;
        },

        getLoginMainId() {
            if (this.isLoginMain()) {
                return this.$session.get(SESSION_MAIN_KEY).coEmailId;
            }
            return this.getVueSessionID();
        },


        getLoginId() {
            if (this.isLogin()) {
                return this.$session.get(SESSION_KEY).coEmailId;
            }
            return this.getVueSessionID();
        },


        /**
         * 현재 로그인 상태
         */
        isLoginMain() {
            return (this.$session.get(SESSION_MAIN_KEY) !== undefined && stringUtils.isEmpty(this.$session.get(SESSION_MAIN_KEY).coEmailId) !== undefined);
        },


        /**
         * 현재 로그인 상태
         */
        isLogin() {
            return (this.$session.get(SESSION_KEY) !== undefined && stringUtils.isEmpty(this.$session.get(SESSION_KEY).coEmailId) !== undefined);
        },

        requireLoginWithoutAlert() {
            if (!this.isLogin()) {
                location.href = "/profiles/"

                // this.$router.push({
                //     name: "profile",
                // })
            }
        },

        requireLoginMainWithoutAlert() {
            if (!this.isLoginMain()) {
                location.href = "/snsJoin"

                // this.$router.push({
                //     name: "profile",
                // })
            }
        },

        /**
         * 로그인 확인 후 로그인 화면 이동
         * @param $this
         * @param _isCancel false 일 경우 alert 후 강제 로그인 이동
         * @returns {Promise<unknown>|boolean}
         */
        requireLogin($this, _isCancel = true) {
            if (!this.isLogin()) {

                return new Promise((resolve, reject) => {
                    // $this.swalUtils.fire("로그인 후 사용하실 수 있습니다.", "확인 버튼을 누르시면 로그인 페이지로 이동합니다.", "info", null, true)
                    this.swalUtils.fire("로그인 후 사용가능합니다.", "로그인 화면으로 이동합니다.", "info", null, _isCancel)
                        .then((result) => {

                            let temp = "";
                            if (Object.keys(this.$route.query).length > 0) {
                                temp = "?" + Qs.stringify(this.$route.query)
                            }
                            temp = location.pathname + temp

                            if (result.isConfirmed) {
                                if (!this.isLogin()) {

                                    location.href = "/profiles/"

                                    // this.$router.push({
                                    //     name: "profile",
                                    // })
                                }
                            } else {
                                if (!_isCancel) {
                                    if (!this.isLogin()) {

                                        location.href = "/profiles/"

                                        // this.$router.push({
                                        //     name: "profile",
                                        // })
                                    }
                                }
                                return false;
                            }
                        })
                })
            }

            return true;
        },


        /**
         * 로그인 확인 후 로그인 화면 이동
         * @param $this
         * @param _isCancel false 일 경우 alert 후 강제 로그인 이동
         * @returns {Promise<unknown>|boolean}
         */
        requireLoginMain($this, _isCancel = true) {
            if (!this.isLoginMain()) {

                return new Promise((resolve, reject) => {
                    // $this.swalUtils.fire("로그인 후 사용하실 수 있습니다.", "확인 버튼을 누르시면 로그인 페이지로 이동합니다.", "info", null, true)
                    this.swalUtils.fire("로그인 후 사용가능합니다.", "로그인 화면으로 이동합니다.", "info", null, _isCancel)
                        .then((result) => {

                            let temp = "";
                            if (Object.keys(this.$route.query).length > 0) {
                                temp = "?" + Qs.stringify(this.$route.query)
                            }
                            temp = location.pathname + temp

                            if (result.isConfirmed) {
                                if (!this.isLogin()) {

                                    location.href = "/snsJoin"

                                    // this.$router.push({
                                    //     name: "login",
                                    // })
                                }
                            } else {
                                if (!_isCancel) {
                                    if (!this.isLogin()) {

                                        location.href = "/snsJoin"

                                        // this.$router.push({
                                        //     name: "login",
                                        // })
                                    }
                                }
                                return false;
                            }
                        })
                })
            }

            return true;
        },

        JSESSIONID() {
            if (this.isLogin()) {
                return this.$session.get(SESSION_KEY).sessionId;
            } else {
                return null;
            }
        },

        /**
         * 세션키에 데이터 입력
         * @param _obj
         * @param _opener
         */
        setMainSessionProc(_obj, _opener = null) {

            const self = this
            return new Promise((resolve) => {

                self.$session.remove(SESSION_MAIN_KEY)

                if (_obj.authEmail === false && _obj.isSnsLogin === false) {

                    resolve(false)

                    const url = "/emailJoinAuth?caidx=" + _obj.caidx
                    if (_opener == null) {

                        this.$router.push({
                            name: "emailJoinAuth",
                            query: {
                                caidx: _obj.caidx
                            }
                        })

                    } else {

                        self.appClose({
                            url: url
                        })
                    }

                } else {
                    self.$session.remove(SESSION_MAIN_KEY)
                    // self.$session[SESSION_DATE] = new Date()
                    self.$session.set(SESSION_MAIN_KEY, _obj)
                    self.$session.start()

                    resolve(true)

                }

            })
        },

        setSessionProc(_obj) {

            const self = this
            return new Promise((resolve) => {

                self.$session.remove(SESSION_KEY)
                self.$session[SESSION_DATE] = new Date()
                self.$session.set(SESSION_KEY, _obj)
                self.$session.start()

                resolve(true)

            })
        },

        setMainSessionKeep(_isKeep) {

            const self = this
            if (this.isLoginMain()) {
                var temp = self.$session.get(SESSION_MAIN_KEY)
                temp.isKeepSession = _isKeep;
                self.$session.set(SESSION_MAIN_KEY, temp);
            }
        },

        setSessionKeep(_isKeep) {

            const self = this
            if (this.isLogin()) {
                var temp = self.$session.get(SESSION_KEY)
                temp.isKeepSession = _isKeep;
                self.$session.set(SESSION_KEY, temp);
            }
        },

        /**
         * 실제 로그인 호출
         * @param _userid
         * @param _passwd
         */
        doLoginProc(_userid, _callback) {

            this.$session.remove(SESSION_KEY)
            this.$eventBus.$emit("startProgress", 2)
            this.axiosCaller.post(this, this.APIs.SESSION + "/doProfileLogin", {
                userid: _userid,
                deviceToken: this.webUtils.isDom('#deviceToken') ? document.querySelector('#deviceToken').value : ""
            }, async (res) => {
                this.$eventBus.$emit("doneProgress", 2)
                let result = res.data

                if (result.status === "ok") {

                    if (result.coAccountVO.isUse === true) {
                        if (await this.setSessionProc(result.coAccountVO)) {

                            if (_callback != null) {
                                _callback()
                                return
                            } else {
                                location.reload()
                            }
                        }
                    } else {
                        this.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null)
                    }

                } else {
                    this.swalUtils.gritter("일치하는 계정정보가 없습니다.", result.msg, "question", 1500)
                    //this.swalUtils.gritter("Fail", "일치하는 회원정보가 없습니다.", "question")
                    return;
                }
            })

        },


        doLoginMainProc(_userId, _password, _callback) {
            this.$session.remove(SESSION_MAIN_KEY)
            this.$eventBus.$emit("startProgress", 2)
            this.axiosCaller.post(this, this.APIs.SESSION + "/doLogin", {
                userid: _userId,
                passwd: _password,
                deviceToken: this.webUtils.isDom('#deviceToken') ? document.querySelector('#deviceToken').value : ""
            }, async (res) => {
                this.$eventBus.$emit("doneProgress", 2)

                let result = res.data

                if (result.status === "ok") {

                    if (result.coAccountVO.isUse === true) {

                        this.$session.set(SESSION_MAIN_KEY, res.data.coAccountVO)

                        if (await this.setMainSessionProc(result.coAccountVO)) {
                            if (_callback != null) {
                                _callback()
                                return
                            } else {
                                location.reload()
                            }
                        }
                    } else {
                        this.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null)
                    }

                } else {
                    this.swalUtils.gritter("일치하는 계정정보가 없습니다.", result.msg, "question", 1500)
                    this.$eventBus.$emit("doneProgress", 2)
                    //this.swalUtils.gritter("Fail", "일치하는 회원정보가 없습니다.", "question")
                    return;
                }
            })

        },


        doLogout() {

            this.$eventBus.$emit("startProgress", 2)
            this.$nextTick(() => {
                this.$session.destroy()
                this.$eventBus.$emit("doneProgress", 2)

                location.reload()
            })

        },
    
        doLogoutWithOutReload() {
        
            this.$eventBus.$emit("startProgress", 2)
            this.$nextTick(() => {
                this.$session.destroy()
                this.$eventBus.$emit("doneProgress", 2)
            })
        
        },

        doLogoutProfile() {

            this.$eventBus.$emit("startProgress", 2)
            this.$nextTick(() => {
                this.$session.remove(SESSION_KEY)
                this.$eventBus.$emit("doneProgress", 2)

            })

        },

        doLogoutProfile4condition(condition, callback) {
            this.$eventBus.$emit("startProgress", 2)
            this.$nextTick(() => {
                this.$nextTick(() => {
                    commonUtils.callFn(callback)
                })
                condition === true ? this.$session.remove(SESSION_KEY) : {}
                this.$eventBus.$emit("doneProgress", 2)
            })
        },


        /**
         * 마지막 데이터 통신 기록시간 저장
         */
        doSyncdate() {
            const currDate = new Date().getTime()
            this.$session.set(SESSION_DATE, currDate)
            console.log(this.getLastTimes())
        },


        getLastTimes() {
            return new Date(this.$session.get(SESSION_DATE));
        },


        isTimeout() {

            // 로그인 유지 옵션 확인
            if (!this.getSessionProc().isKeepSession) {
                if (this.isLogin() && (new Date()).getTime() - this.getLastTimes().getTime() > TIMEOUT) {
                    console.log("SESSION TIME OUT!!!")
                    this.swalUtils.gritter("로그아웃 됩니다.", "로그인 유지 시간이 초과되어 로그아웃 됩니다.", "info")
                    this.doLogout();
                    return false;
                }
            }

        },

        getCookie(name) {    //쿠키 존재 확인 (없으면 null 반환)
            var dc,
                prefix,
                begin,
                end;

            dc = document.cookie;
            prefix = name + "=";
            begin = dc.indexOf("; " + prefix);
            end = dc.length;

            if (begin !== -1) {

                begin += 2;
            } else {

                begin = dc.indexOf(prefix);

                if (begin === -1 || begin !== 0) return null;
            }

            if (dc.indexOf(";", begin) !== -1) {
                end = dc.indexOf(";", begin);
            }

            return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\"/g, '');
        },


        test() {
            console.log(this.$session.getAll())
        }


    },
    filters: {

        getLoginSession() {
            if (this.$session.get(SESSION_KEY) === undefined) {
                return null;
            }
            return this.$session.get(SESSION_KEY);
        },

    },

}
