import "@babel/polyfill";
import Vue from 'vue'
import routes from './vue.routes'
import App from '@/App'
import Meta from 'vue-meta'

import _ from 'lodash'

Vue.prototype._ = _


// set vue-session
import VueSession from 'vue-session'
Vue.use(VueSession, {
	persist: true
})
Vue.use(Meta, {
	attribute: 'data-vue-meta',
	refreshOnceOnNavigation: true
});


// eventBus definitions
Vue.prototype.$eventBus = new Vue();






// add library
import $ from 'jquery'
// import jQuery from 'jquery'
window.$ = $






// add mixins
import CommonsMixin from '@/commons/mixins/commons.mixin'   // 공통
import SessionMixin from '@/commons/mixins/sessions.mixin'  // 세션
import HWAPP from '@/commons/mixins/hwapp.mixin'  // 앱 브릿지

import BoardVOs from '@/commons/vo/boards'  // 게시판
import CompanyVOs from '@/commons/vo/company' // 회사, 계정
import EducationVOs from '@/commons/vo/educations'  // 교육
import CartVOs from '@/commons/vo/cart' //장바구니
import OrderInfoVOs from '@/commons/vo/order' //주문
import DisplayVOs from '@/commons/vo/displays' //주문


Vue.mixin(CommonsMixin)
Vue.mixin(SessionMixin)
Vue.mixin(HWAPP)
Vue.mixin(BoardVOs)
Vue.mixin(CompanyVOs)
Vue.mixin(EducationVOs)
Vue.mixin(CartVOs)
Vue.mixin(OrderInfoVOs)
Vue.mixin(DisplayVOs)




// add utils
import APIs from '@/commons/APIs'
import axiosCaller from '@/commons/utils/axios.caller'
import webUtils from '@/commons/utils/web.utils'
import pageUtils from '@/commons/utils/page.utils'
import stringUtils from '@/commons/utils/string.utils'
import arrayUtils from '@/commons/utils/array.utils'
import numberUtils from '@/commons/utils/number.utils'
import swalUtils from '@/commons/utils/swal.utils'
import codeUtils from '@/commons/utils/code.utils'
import commonUtils from '@/commons/utils/common.utils'
import sessionUtils from '@/commons/utils/session.utils'

Vue.prototype.$ = $;
Vue.prototype.APIs = APIs;
Vue.prototype.axiosCaller = axiosCaller;
Vue.prototype.webUtils = webUtils;
Vue.prototype.pageUtils = pageUtils;
Vue.prototype.stringUtils = stringUtils;
Vue.prototype.arrayUtils = arrayUtils;
Vue.prototype.numberUtils = numberUtils;
Vue.prototype.swalUtils = swalUtils;
Vue.prototype.codeUtils = codeUtils;
Vue.prototype.commonUtils = commonUtils;
Vue.prototype.sessionUtils = sessionUtils;




// add filters
import "@/commons/filters/array.filter"
import "@/commons/filters/date.filter"
import "@/commons/filters/number.filter"
import "@/commons/filters/string.filter"






// publish js
import Swiper from '@/../public/assets/js/swiper-bundle.min'
Vue.prototype.Swiper = Swiper




// External JS LOAD Library
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);



// add vue-sweetalert2
import vueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(vueSweetalert2)





// Font Awesome
import '@fortawesome/fontawesome-free/js/all'
// import '@fortawesome/fontawesome-free/js/fontawesome'
// import '@fortawesome/fontawesome-free/js/brands'



// add Message Kor
/*import message_kor from '@/commons/mixins/messages_kor.mixin'
window.$messages = message_kor
Vue.prototype.$messages = message_kor*/



Vue.config.productionTip = false

new Vue({
	// render: h => h(App),
	render: h => h(App),
	router: routes,
}).$mount('#app')