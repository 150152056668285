export default {

    methods : {

        CartVO( $_session ) {
            this.cidx=0;
            this.gid=0;
            this.count=0;
            this.userId = ($_session != null) ? $_session.coEmailId : "";  //id
        },


    }

}