export default {

    methods : {


        goAppEducation( _cateCode, _emIdx = 0 ) {

            let url = "/app/education/main/" + _cateCode + "/";
            if ( _emIdx > 0 ) {
                url += "?MODE=LEARN&emIdx=" + _emIdx
            }
            location.href = url
        },

        EduMainVO() {
            this.emIdx = 0;
            this.shortTitle = "";
            this.eduTitle = ""; //교육 명
            this.introduce = ""; // 개요
            this.goal = ""; // 목표
            this.startDate; // 교육 시작일
            this.endDate; // 교육 마감일
            this.isMajor = false; // 주요교육 여부
            this.isDisplay = false;  // 교육 공개 여부 ( 전체컨텐츠 등록 전엔 무조건 false + 컨텐츠 수정시 false 처리 )
            this.point; //교육 완료시 지급될 포인트
            this.memberLevel = 0; //대상 회원 레벨벨
            this.displayNum = 0;    // 교육 표출용 번호 TODO : MAX 계산
            this.numLeft = 0;    // 선착순 제한인원수 TODO : 0일 경우 무제한
            this.cateCode; // 카테고리
            this.allStudents = false; // 전체 대상
            this.teachId; // 강사 ID (존재시)
            this.teachName; // 강사명
            this.thumbnail; // 강의 썸네일 url
            this.countConfig = 0; // 구성 개수
            this.contentText = ""; //교육 소개
            this.views = 0;	// 조회수
            this.contentHtml = ""; //교육 소개 HTML
            this.rateCode = 0; // 공개 회원 등급

            this.studentAllCount = 0;
            this.studentCount = 0;
            this.requestCount = 0;
            this.completeCount = 0;
            this.likeCount = 0;
            this.userCount = 0;
            this.teamCount = 0;
            this.rateCount = 0;

            this.contentList = [];
            this.cateCodeVO = {};
            this.studentList = [];
            this.targetList = [];
            this.studentVO = {};

            this.isOpen = false; // 현재 교육 오픈 여부 : 독립일정 + 기관일정 + 공용일정 필터링
            this.isActive = false;  // 현재 교육 진행여부: 현재 열린 교육중 최신교육으로 진행중 처리
        },



        EduCourseVO() {

            this.cidx;
            this.shortTitle = "";
            this.title = "";
            this.comment = "";
            this.teacherName = "";
            this.cateCode = "";
            this.cateCodeVO = {};
            this.allStudents = false; // 전체 대상
            this.scheduleMode = "";
            this.startDate = "";
            this.endDate = "";
            this.itemList = [];
            this.itemCount = 0;
            this.eduMainList = [];
            this.targetList = [];
            this.isOpen = false; // 현재 교육 오픈 여부 : 독립일정 + 기관일정 + 공용일정 필터링
            this.isActive = false;  // 현재 교육 진행여부: 현재 열린 교육중 최신교육으로 진행중 처리

        },


        /**
         * 교육 신청자 정보
         * @constructor
         */
        EduStudentVO: function () {

            this.sidx = 0;
            this.emIdx = 0;

            this.userId;
            this.userIdDec;
            this.isRequest = false;

            this.checkDate;
            this.requestDate;
            this.completeDate;


        },



        EduContentVO: function () {

            this.ecIdx = 0;
            this.emIdx = 0;
            this.ordering = 0;
            this.contentType = "";
            this.qstMovVO = {};
            this.qstFileVO = {};
            this.qstTstList = [];

            this.recordCount = 0;
            this.recordDate = "";
        },





        QstMov: function () {
            this.ecIdx = 0;
            this.movTitle = "";
            this.movUrl = "";
            this.hashParams = "";
            this.movThumb = "";
            this.isSpeed = false;
            this.isSkip = false;
            this.playMinutes = 0;
            this.playSeconds = 0;
            this.note = "";
        },





        QstFileVO: function () {

            this.ecIdx = 0;
            this.fileDesc = "";
            this.fileList = [];

        },



        QstTst: function () {
            this.qIdx = 0
            this.ecIdx = 0
            this.cateCode = null
            this.tstTitle = ""
            this.tstDesc = ""
            this.tstType = ""

            this.isTxt = false
            this.isImg = false
            this.isAudio = false
            this.isOX = false
            this.isDrag = false
            this.tstImgSrc = ""
            this.tstAudioSrc = ""

            this.ansType = "txt"

            this.directCorrect = false
            this.answerList = []
        },


        TstAns: function () {
            this.taIdx = 0
            this.qIdx = 0
            this.answer = ""
            this.answerImgSrc = ""
            this.answerAudioSrc = ""
            this.isCorrect = false;
        },








        EduAnswerVO: function () {

            this.eaIdx = 0;
            this.ecIdx = 0;
            this.userId = "";
            this.contentType = "";
            this.ansMovVO = {};
            this.ansTstList = [];
            this.ansFileList = [];

        },


        AnsMovVO: function () {

            this.amIdx = 0;
            this.eaIdx = 0;
            this.currentTimes = 0;
            this.completeDate = 0;
            this.userId = "";

        },


        AnsFileVO: function () {

            this.afIdx = 0;
            this.eaIdx = 0;
            this.isDownload = false;
            this.fidx = 0;

        },


        AnsTstVO: function () {

            this.seq = 0;
            this.eaIdx = 0;
            this.qtIdx = 0;

            this.taIdx = 0;
            this.taIdxs = [];
            this.selTaIdxs = "";

            this.userId = "";
            this.shortTxt = "";

        },


    }

}
