import Vue from 'vue'
import $ from 'jquery'

/*Vue.filter("nl2br", (entry) => {

	var reg = /\n/g;
	if (entry && entry !== null) {
		let i, s = '';
		let lines = entry.split(reg)
		let l = lines.length;

		for (i = 0; i < l; ++i) {
			s += lines[i];
			(i !== l - 1) && (s += '<br/>');
		}

		return s;
	}

	console.log(entry)
	return entry;
});*/

Vue.filter("nl2br", (entry) => {
	if(!entry){
		return ''
	}
	//console.log(entry)
	return entry.replace(/\r\n|\n/g, '<br />')
});

Vue.filter("br2nl", (entry) => {
	return entry.replace(/<br\s*\/?>/mg, "\n")
});


Vue.filter("isEmpty", (args) => {
	if ( $.trim( args ).length <= 0 || args === null || args === undefined ) {
		return true;
	} else {
		return false;
	}
})



Vue.filter("rightString", (args, len) => {
	if ( $.trim( args ).length > 0 ) {
		return args.substring( args.length - len, args.length );
	} else {
		return "";
	}
})