<template>

    <!DOCTYPE html>
    <html lang="ko">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport">
        <title>Masha and the Bear</title>
    </head>
    <body>

    <loading/>

    <transition name="fade" mode="out-in">
	    <router-view name="rv-container"></router-view>
    </transition>


    <!--	<script src="/assets/js/jquery-3.3.1.min.js"></script>
        <script src="/assets/js/common.js"></script>-->


    </body>
    </html>

</template>

<script>
import('@/../public/assets/js/swiper-bundle.min')
import('@/../public/assets/js/common')

import Loading from '@/components/loading'
import $ from 'jquery'

export default {
    name: 'App',
    components: {Loading},
    created() {

    },
    mounted() {

	    //this.$swal(  "타이틀 한글 테스트", "본문 한글 테스트", "warning", "확인")

        this.$eventBus.$on('startProgress', () => {
            this.doShowLoading()
        })

        this.$eventBus.$on('doneProgress', (_hideInterval) => {
            this.doHideLoading(_hideInterval)
        })

    },
    methods: {
        doShowLoading() {

            $(this.$children[1]).css('overflow', 'hidden')

        },

        doHideLoading(_hideInterval) {

            setTimeout(() => {
                $(this.$children[1]).css('overflow', 'visible')
            }, _hideInterval)

        }

    }
}
</script>

<style>
@import "/assets/css/style.css";
@import "/assets/css/common.css";
@import "/assets/css/content.css";
@import "/assets/css/reset.css";
@import "/assets/css/style.css";
@import "/assets/css/swiper-bundle.min.css";

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0
}

</style>
