
import $ from 'jquery'

const StringUtils = {

	isEmpty: function($_str) {
		if ( $_str === undefined || $_str === null || $.trim( $_str ) === '') {
			return true;
		} else {
			return false;
		}
	},


	val ($_str) {
		if (this.isEmpty($_str)) {
			return "";
		} else {
			return $_str;
		}
	},


	valInt ($_str) {
		if (this.isEmpty($_str)) {
			return 0;
		} else {
			return parseInt($_str);
		}
	},


	nl2br: function(str) {
		if(!str){
			return ''
		}
		return str.replace(/\r\n|\n/g, '<br />')
	},


	idFilter ( userId ) {
		if ( userId.length < 4 ){
			return userId
		}else{
			switch (userId.length) {
				case 4:
					return userId.substr(0,3) + '*'
				case 5:
					return userId.substr(0,3) + '**'
				default:
					return userId.substr(0,3) + '**' + userId.substr(5, userId.length)
			}
		}
	},

	rightString (args, len) {
		if ( $.trim( args ).length > 0 ) {
			return args.substring( args.length - len, args.length );
		} else {
			return "";
		}
	}


}
export default StringUtils;