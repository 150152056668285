export default {

	methods : {


		goAppBoard( _boardKey, _idx = 0 ) {

			let url = "/app/board/" + _boardKey + "/";
			if ( _idx > 0 ) {
				url += "?idx=" + _idx
			}
			location.href = url
		},


		BoardInfoVO() {
			this.idx = 0;
			this.boardKey;
			this.boardName;
			this.boardMode = "normal";
			this.isNotice = false;
			this.isComment = false;
			this.isFile = true;
			this.isThumbnail = true;
			this.isSecret = false;
			this.isNologin = false;
			this.isCategory = false;
			this.isPush = false;
			this.cateCode;
			this.rateCode;
			this.pageCount = 15;
			this.description;
		},

		BoardVO( $_session ) {

			this.idx = 0;
			this.pidx = 0;
			this.treeLevel = "";
			this.depth = 0;
			this.boardKey;
			this.writerKey = ($_session != null) ? $_session.coEmailId : "";  //id
			this.writer = ($_session != null) ? $_session.nickName : "";  //닉네임
			this.writerPw;
			this.title;
			this.contentText;
			this.contentHtml;
			this.isUse = true;
			this.isNotice = false;
			this.isSecret = false;
			this.regIp;
			this.views = 0;
			this.thumbIdx = null;
			this.opt1;
			this.opt2;
			this.opt3 = "#2d353c";
			this.cateCode = "";
			this.thumbFile = null;
			this.writerKeyDec;  //id Decrypt
			this.writerDec;  //이름 Decrypt
			this.attachList;
			this.commentCount = 0;
			this.commentList;
			this.reply;
			this.isNew = false;
			this.replyCnt = 0;

		},


		CommentVO( $_session ) {
			this.idx = 0;
			this.bidx = 0;
			this.writerKey = ($_session != null) ? $_session.coEmailId : "";  //id
			this.writer = ($_session != null) ? $_session.nickName : "";  //닉네임
			this.contentText = "";
		},


		LikeVO ( $this ) {
			this.seq = 0;
			this.bidx = 0;
			this.isMyLike = false;
			this.userId = $this.getLoginId();  //id
		},





		getBoardInfo ( _boardKey ) {

			return new Promise(resolve => {
				this.axiosCaller.get( this, this.APIs.BOARDS + "/getBoardInfo", {
					boardKey: _boardKey
				}, (res) => {
					var result = res.data
					if ( result.status === "ok" ) {
						resolve( result.boardInfoVO );
						//this.getBoardList();

					} else {
						this.swalUtils.gritter("Error", result.msg, "error")
							.then((result) => {
								location.href = document.referrer
							})
					}
				});
			})
		},


	}

}