<template>
    <div class="wrap p-loading hide">
        <!-- container -->
        <div class="container">
            <div class="content">
                <div class="img-wrap">
                    <img alt="" src="/assets/images/learning/img_loading.gif">
                </div>
                <div class="loading-text">Loading...</div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

export default {
    name: "loading",
    data() {

        const loadingDom = $('.p-loading')

        return {
            loadingDom,
        }
    },
    created() {
    },
    mounted() {

        this.$eventBus.$on('startProgress', () => {
            this.doShowLoading()
        })

        this.$eventBus.$on('doneProgress', (_hideInterval) => {
            this.doHideLoading(_hideInterval)
        })

    },
    methods: {

        doShowLoading() {
            const self = this
            $(self.$el).removeClass('hide')
            $(self.$el).addClass('show')
        },

        doHideLoading(_hideInterval = 0) {
            const self = this
            setTimeout(() => {
                $(self.$el).removeClass('show')
                $(self.$el).addClass('hide')
            }, _hideInterval)
        }

    }
}
</script>

<style scoped>
.p-loading{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.show {
    z-index: 1000;
    display: flex;
}

.hide {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    display: none;
}

.p-loading .container {
    width: 100%;
}
</style>