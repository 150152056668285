import Vue from 'vue'
import moment from 'moment'




Vue.filter("dateFormatFilter", (entry, format) => {
	return moment(entry).format(format);
});

Vue.filter("dateFilter", (entry) => {
	if ( entry === null || entry === undefined || entry === "") {
		return ""
	}
	return moment(entry).format("YYYY.MM.DD");
});

Vue.filter("dateFilterDay", (entry) => {

	entry = new Date(entry)
	entry.setDate(entry.getDate() - 1);

	return moment(entry).format("YYYY.MM.DD");
});

Vue.filter("datetimeFilter", (entry) => {
	return moment(entry).format("YYYY.MM.DD HH:mm:ss");
});

Vue.filter("timeFilter", (entry) => {
	return moment(entry).format("HH:mm:ss");
});