
import Vue from "vue";
import vueSweetalert2 from "vue-sweetalert2";
Vue.use(vueSweetalert2)

const SwalUtils = {


	setDefaultOptions( _title, _html, _icon) {
		var options = {}
		if ( _title != null ) options.title = _title
		if ( _html != null ) options.html = _html
		if ( _icon != null ) options.icon = _icon
		return options;
	},


	fire( _title, _html, _icon, _showCancelButton = null, _showDenyButton = null ) {

		var options = this.setDefaultOptions(_title, _html, _icon);

		options.confirmButtonText = 'OK'
		if ( _showCancelButton != null ) {
			options.showCancelButton = _showCancelButton
			options.confirmButtonText = 'YES'
			options.cancelButtonText = 'NO'
		}
		if ( _showDenyButton != null ) {
			options.showCancelButton = false
			options.showDenyButton = _showDenyButton
			options.confirmButtonText = 'OK'
			options.denyButtonText = 'Cancel'
		}

		return Vue.swal(options)
	},



	gritter(_title, _html, _icon, _timer) {

		var options = this.setDefaultOptions(_title, _html, _icon);
		( _timer == null ) ? options.timer = 3000 : options.timer = _timer
		options.showConfirmButton = false;
		options.position = 'top-end'
		return Vue.swal(options);
	}

}

export default SwalUtils;
