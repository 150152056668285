import _ from 'lodash'
import commonUtils from "@/commons/utils/common.utils"
import APIs from "@/commons/APIs.js"

const LOGIN_KEY = APIs.LOGIN_KEY
const PROFILE_KEY = APIs.PROFILE_KEY

export default {

	/**
	 *
	 * SESSION STORAGE FUNCTION
	 *
	 */

	/**
	 * Session Storage 에 Key, Value 를 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 저장하지 않습니다.
	 *
	 * @param _k
	 * @param _v
	 */
	sessionStorageUniqueSet: function (_k, _v) {
		let t = {}
		t[_k] = _v
		this.sessionStorageUniqueSetMany(t)
	},

	/**
	 * Session Storage 에 Key, Value 여러 쌍을 저장합니다.
	 * 각 Key 에 대한 Value 가 존재할 경우 저장하지 않습니다.
	 *
	 * @param _map
	 */
	sessionStorageUniqueSetMany: function (_map) {
		_.forEach(_map, (v, k) => {
			if (commonUtils.nullCheck(sessionStorage.getItem(k))) sessionStorage.setItem(k, v)
		})
	},

	/**
	 * Session Storage 에 Key, Value 를 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 Update 합니다.
	 *
	 * @param _k
	 * @param _v
	 */
	sessionStorageSet: function (_k, _v) {
		let t = {}
		t[_k] = _v
		this.sessionStorageSetMany(t)
	},

	/**
	 * Session Storage 에 Key, Value 여러 쌍을 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 Update 합니다.
	 *
	 * @param _map
	 */
	sessionStorageSetMany: function (_map) {
		_.forEach(_map, (v, k) => {
			sessionStorage.setItem(k, v)
		})
	},

	/**
	 * Session Storage 에서 Key 에 대한 Value 를 가져옵니다.
	 * Return Type : Map (Key: Value)
	 *
	 * @param _v
	 * @returns {{}}
	 */
	sessionStorageGet: function (_v) {
		return this.sessionStorageGetMany([_v])
	},

	/**
	 * Session Storage 에서 Key 에 대한 Value 만 가져옵니다.
	 *
	 * @param _v
	 * @returns {{}}
	 */
	sessionStorageGetValue: function (_v) {
		return this.sessionStorageGetMany([_v])[_v]
	},

	/**
	 * Session Storage 에서 각 Key 에 대한 Value 를 가져옵니다.
	 * Return Type : Map (Key: Value, Key: Value...)
	 *
	 * @param _arr
	 * @returns {{}}
	 */
	sessionStorageGetMany: function (_arr) {
		let res = {}
		_.forEach(_arr, (v, i) => {
			res[_arr[i]] = sessionStorage.getItem(v)
		})
		return res
	},

	/**
	 * Session Storage 에서 Key 에 대한 Value 를 제거합니다.
	 * Parameter 로 빈 값이 전달 될 경우 전체 삭제합니다.
	 *
	 * @param _v
	 */
	sessionStorageDelete: function (_v) {
		this.sessionStorageDeleteMany([_v])
	},

	/**
	 * Session Storage 에서 각 Key 에 대한 Value 를 제거합니다.
	 * Parameter 로 빈 값이 전달 될 경우 전체 삭제합니다.
	 *
	 * @param _arr
	 */
	sessionStorageDeleteMany: function (_arr) {
		if (commonUtils.nullCheck(_arr)) this.sessionStorageDeleteAll()
		_.forEach(_arr, (v) => {
			sessionStorage.removeItem(v)
		})
	},

	/**
	 * Session Storage 에 존재하는 모든 Value 를 제거합니다.
	 *
	 */
	sessionStorageDeleteAll: function () {
		sessionStorage.clear()
	},

	/**
	 * Session Storage 중 특정 키의 값이 parameter 로 보낸 _chkVal 인지 확인합니다.
	 *
	 * @param _k
	 * @param _chkVal
	 * @returns {boolean}
	 */
	sessionStorageIsEqValue: function (_k, _chkVal) {
		return this.sessionStorageGetValue(_k) === _chkVal
	},

	/**
	 *
	 * LOCAL STORAGE FUNCTION
	 *
	 */

	/**
	 * Local Storage 에 Key, Value 를 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 저장하지 않습니다.
	 *
	 * @param _k
	 * @param _v
	 */
	localStorageUniqueSet: function (_k, _v) {
		let t = {}
		t[_k] = _v
		this.localStorageUniqueSetMany(t)
	},

	/**
	 * Local Storage 에 Key, Value 여러 쌍을 저장합니다.
	 * 각 Key 에 대한 Value 가 존재할 경우 저장하지 않습니다.
	 *
	 * @param _map
	 */
	localStorageUniqueSetMany: function (_map) {
		_.forEach(_map, (v, k) => {
			if (commonUtils.nullCheck(sessionStorage.getItem(k))) localStorage.setItem(k, v)
		})
	},

	/**
	 * Local Storage 에 Key, Value 를 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 Update 합니다.
	 *
	 * @param _k
	 * @param _v
	 */
	localStorageSet: function (_k, _v) {
		let t = {}
		t[_k] = _v
		this.localStorageSetMany(t)
	},

	/**
	 * Local Storage 에 Key, Value 여러 쌍을 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 Update 합니다.
	 *
	 * @param _map
	 */
	localStorageSetMany: function (_map) {
		_.forEach(_map, (v, k) => {
			localStorage.setItem(k, v)
		})
	},

	/**
	 * Local Storage 에서 Key 에 대한 Value 를 가져옵니다.
	 * Return Type : Map (Key: Value)
	 *
	 * @param _v
	 * @returns {{}}
	 */
	localStorageGet: function (_v) {
		return this.localStorageGetMany([_v])
	},

	/**
	 * Local Storage 에서 Key 에 대한 Value 만 가져옵니다.
	 *
	 * @param _v
	 * @returns {*}
	 */
	localStorageGetValue: function (_v) {
		return this.localStorageGetMany([_v])[_v]
	},

	/**
	 * Local Storage 에서 각 Key 에 대한 Value 를 가져옵니다.
	 * Return Type : Map (Key: Value, Key: Value...)
	 *
	 * @param _arr
	 * @returns {{}}
	 */
	localStorageGetMany: function (_arr) {
		let res = {}
		_.forEach(_arr, (v, i) => {
			res[_arr[i]] = localStorage.getItem(v)
		})
		return res
	},

	/**
	 * Local Storage 에서 Key 에 대한 Value 를 제거합니다.
	 * Parameter 로 빈 값이 전달 될 경우 전체 삭제합니다.
	 *
	 * @param _v
	 */
	localStorageDelete: function (_v) {
		this.localStorageDeleteMany([_v])
	},

	/**
	 * Local Storage 에서 각 Key 에 대한 Value 를 제거합니다.
	 * Parameter 로 빈 값이 전달 될 경우 전체 삭제합니다.
	 *
	 * @param _arr
	 */
	localStorageDeleteMany: function (_arr) {
		if (commonUtils.nullCheck(_arr)) this.sessionStorageDeleteAll()
		_.forEach(_arr, (v) => {
			localStorage.removeItem(v)
		})
	},

	/**
	 * Local Storage 에 존재하는 모든 Value 를 제거합니다.
	 *
	 */
	localStorageDeleteAll: function () {
		localStorage.clear()
	},

	/**
	 * Local Storage 중 특정 키의 값이 parameter 로 보낸 _chkVal 인지 확인합니다.
	 *
	 * @param _k
	 * @param _chkVal
	 * @returns {boolean}
	 */
	localStorageIsEqValue: function (_k, _chkVal) {
		return this.localStorageGetValue(_k) === _chkVal
	},

	/**
	 *
	 * COOKIE FUNCTION
	 *
	 */

	/**
	 * Cookie 에 Key, Value 를 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 저장하지 않습니다.
	 * _path : 쿠키 저장 경로
	 * _domain : 접근 허용 가능한 도메인
	 * _maxAge : 해당 쿠키의 유효 시간 (초)
	 *
	 * @param _k
	 * @param _v
	 * @param _path
	 * @param _domain
	 * @param _maxAge
	 */
	cookieUniqueSet: function (_k, _v, _path, _domain, _maxAge) {
		let t = {}
		t[_k] = _v
		this.cookieUniqueSetMany(t, _path, _domain, _maxAge)
	},

	/**
	 * Cookie 에 Key, Value 여러 쌍을 저장합니다.
	 * 각 Key 에 대한 Value 가 존재할 경우 저장하지 않습니다.
	 * _path : 쿠키 저장 경로
	 * _domain : 접근 허용 가능한 도메인
	 * _maxAge : 해당 쿠키의 유효 시간 (초)
	 *
	 * @param _map
	 */
	cookieUniqueSetMany: function (_map, _path, _domain, _maxAge) {
		_.forEach(_map, (v, k) => {
			if (commonUtils.nullCheck(this.cookieGet(k)[k])) document.cookie = k + '=' + v + '; ' +
				(commonUtils.nullCheck(_path) ? '' : 'path=/"' + _path + '"; ') +
				(commonUtils.nullCheck(_domain) ? '' : 'domain"' + _domain + '"; ') +
				(commonUtils.nullCheck(_maxAge) ? '' : 'max-age="' + _maxAge + '";')
		})
	},

	/**
	 * Cookie 에 Key, Value 를 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 Update 합니다.
	 * _path : 쿠키 저장 경로
	 * _domain : 접근 허용 가능한 도메인
	 * _maxAge : 해당 쿠키의 유효 시간 (초)
	 *
	 * @param _k
	 * @param _v
	 */
	cookieSet: function (_k, _v, _path, _domain, _maxAge) {
		let t = {}
		t[_k] = _v
		this.cookieSetMany(t, _path, _domain, _maxAge)
	},

	/**
	 * Cookie 에 Key, Value 여러 쌍을 저장합니다.
	 * Key 에 대한 Value 가 존재할 경우 Update 합니다.
	 * _path : 쿠키 저장 경로
	 * _domain : 접근 허용 가능한 도메인
	 * _maxAge : 해당 쿠키의 유효 시간 (초)
	 *
	 * @param _map
	 */
	cookieSetMany: function (_map, _path, _domain, _maxAge) {
		_.forEach(_map, (v, k) => {
			document.cookie = k + '=' + v + '; ' +
				(commonUtils.nullCheck(_path) ? '' : 'path=/"' + _path + '"; ') +
				(commonUtils.nullCheck(_domain) ? '' : 'domain"' + _domain + '"; ') +
				(commonUtils.nullCheck(_maxAge) ? '' : 'max-age="' + _maxAge + '";')
		})
	},

	/**
	 * Cookie 에서 Key 에 대한 Value 를 가져옵니다.
	 * Return Type : Map (Key: Value)
	 *
	 * @param _v
	 * @returns {{}}
	 */
	cookieGet: function (_v) {
		return this.cookieGetMany([_v])
	},

	/**
	 * Cookie 에서 Key 에 대한 Value 를 가져옵니다.
	 *
	 * @param _v
	 * @returns {*}
	 */
	cookieGetValue: function (_v) {
		return this.cookieGetMany([_v])[_v]
	},

	/**
	 * Cookie 에서 각 Key 에 대한 Value 를 가져옵니다.
	 * Return Type : Map (Key: Value, Key: Value...)
	 *
	 * @param _arr
	 * @returns {{}}
	 */
	cookieGetMany: function (_arr) {

		const getCookieValue = (key) => {
			let cookieKey = key + "="
			let result = ""
			const cookieArr = document.cookie.split(";")

			for(let i = 0; i < cookieArr.length; i++) {
				if(cookieArr[i][0] === " ") {
					cookieArr[i] = cookieArr[i].substring(1)
				}

				if(cookieArr[i].indexOf(cookieKey) === 0) {
					result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length)
					return result
				}
			}
			return result
		}

		let res = {}
		_.forEach(_arr, (v, i) => {
			res[_arr[i]] = getCookieValue(v)
		})

		return res

	},

	/**
	 * Cookie 에서 Key 에 대한 Value 를 제거합니다.
	 * Parameter 로 빈 값이 전달 될 경우 전체 삭제합니다.
	 *
	 * @param _v
	 */
	cookieDelete: function (_v) {
		this.cookieDeleteMany([_v])
	},

	/**
	 * Cookie 에서 각 Key 에 대한 Value 를 제거합니다.
	 * Parameter 로 빈 값이 전달 될 경우 전체 삭제합니다.
	 *
	 * @param _arr
	 */
	cookieDeleteMany: function (_arr) {
		if (commonUtils.nullCheck(_arr)) this.cookieDeleteAll()
		_.forEach(_arr, v => {
			document.cookie = v +'='
		})
	},

	/**
	 * Cookie 에 존재하는 모든 Value 를 제거합니다.
	 */
	cookieDeleteAll: function () {
		document.cookie.split(";").forEach(function(c) {document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")})
	},

	/**
	 * Cookie 중 특정 키의 값이 parameter 로 보낸 _chkVal 인지 확인합니다.
	 *
	 * @param _k
	 * @param _chkVal
	 * @returns {boolean}
	 */
	cookieIsEqValue: function (_k, _chkVal) {
		return this.cookieGetValue(_k) === _chkVal
	},

	isLogin: function () {
		return !commonUtils.nullCheck(this.sessionStorageGetValue(LOGIN_KEY))
	},

	isProfileLogin: function () {
		return !commonUtils.nullCheck(this.sessionStorageGetValue(PROFILE_KEY)) && this.isLogin()
	},

	requireLogin: function () {

	},

	requireProfile: function () {

	},

	resetLogin: function () {
	},


}

