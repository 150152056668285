import $ from 'jquery'

const WebUtils = {

    getSubdomain() {
        /*console.log( location.path )
        console.log( location.pathname )
        console.log( location.origin )
        console.log( location.host )
        console.log( location.hostname )*/

        if (location.hostname.indexOf(".") > -1) {
            
            var subDomain = location.hostname.split('.').length > 3 ? location.hostname.split(".")[0] : 'www'
            if (subDomain === "localhost" || !isNaN(subDomain)) {
                subDomain = "www";
            }
            // else {
            //     let urlParts = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$').exec(location.hostname)
            //     subDomain = location.hostname.replace(urlParts[1] + '.' + urlParts[2], '').slice(0, -1)
            //     if (subDomain === '') {
            //         subDomain = "www"
            //     } else {
            //         subDomain = location.hostname.split(".")[0]
            //     }
            // }

            return subDomain;
        }

        // return location.hostname
        return "www"
    },

    getParameter: function (strParamName) {
        var arrResult = null;
        if (strParamName) {
            arrResult = location.search.match(new RegExp("[&?]" + strParamName + "=(.*?)(&|$)"));
        }
        return arrResult && arrResult[1] ? decodeURIComponent(arrResult[1]) : "";
    },

    getParameterInt: (strParamName) => {
        if (WebUtils.getParameter(strParamName) === "") {
            return 0;
        } else {
            return WebUtils.getParameter(strParamName);
        }
    },


    serialize: (obj) => {
        var str = [];
        for (var p in obj)
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + obj[p]);
                // str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    },


    scrollTo: (_domId) => {

        if ($(_domId).position() == null) {
            _domId = "header"
        }
        window.scrollTo({
            top: $(_domId).position().top,
            left: $(_domId).position().left,
            behavior: 'smooth'
        });
    },


    isDom: ($_dom) => {
        return document.querySelector($_dom) instanceof HTMLElement
    }


}
export default WebUtils;